<script>
import BaseArrow from '../base/BaseArrow.vue';
import LocLink from '../base/LocLink.vue';

export default {
  data() {
    return {
      item: null,
    };
  },
  props: [
    'items',
    'solutionTerm'
  ],
  components: {
    BaseArrow,
    LocLink
  },
  computed: {
    getItems() {
      return this.items;
    },
  },
  methods: {
    handleHover(index) {
      this.$refs[('arrow' + index)][0].handleOver();
    },
    handleLeave(index) {
      this.$refs[('arrow' + index)][0].handleOut();
    }
  },
  mounted() {
    this.item = this.$props.items;
  }
};
</script>
<template>
  <div v-show="getItems != undefined" class="home-container">
    <div class="home-section">
      <h4 class="home-section-head" v-show="solutionTerm != null">{{ $rt(solutionTerm.mltText) }}</h4>
      <h2 class="home-section-title">{{ $rt(getItems.title) }}</h2>
      <p class="home-section-paragraph">{{ $rt(getItems.paragraph) }}</p>
      <div class="tg-tiles-home">
        <LocLink class="tg-tile" :to="$rt(item.path)" v-for="item, index in getItems.categories" :key="index"
          @mouseover="handleHover(index)" @mouseleave="handleLeave(index)">
          <div class="tg-tile-img" :style="'background-image: url(' + $rt(item.imageUri) + ')'"></div>
          <div class="tg-tile-title">
            <div class="tg-title">{{ $rt(item.name) }}</div>
            <div class="tileArrow">
              <BaseArrow :ref="'arrow' + index" color="rgb(147, 147, 147)" hoverColor="#ff0000" class="arrow-pos" />
            </div>
          </div>
        </LocLink>
      </div>
    </div>
  </div>
</template>
<style>
.tg-tiles-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.tg-tile-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 60%;
}
.tg-tile-title {
  font-weight: 700;
  text-align: center;
  color: #222222;
  display: flex;
  position: relative;
  justify-content: flex-start;
}
.tg-tile {
  margin-bottom: 2%;
  background-color: #F7F6F1;
  padding: 5px;
  display: block;
  text-decoration: none;
}
.home-section {
  width: 100%;
  max-width: 1200px;
}
.home-section-head {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #3B3B3B;
  text-transform: uppercase;
  margin-left: 20px;
  margin-right: 20px;
}
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 55px;
}
.home-section-title {
  font-size: 35px;
  margin: 15px !important;
}
.home-section-paragraph {
  font-size: 17px !important;
  line-height: 1.6 !important;
  margin: 15px !important;
}
.tg-title {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 8px;
}
.solutions-arrow {
  transform: rotate(0deg) scale(.4) translate(-10px, -22px) !important;
}
@media only screen and (max-width: 1200px) {
  .tg-tiles-home {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 700px) {
  .home-container {
    padding-bottom: 25px;
  }
  .tg-tiles-home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6px;
    row-gap: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>