<script>
import gsap from 'gsap/src';
import BaseArrow from '~/components/base/BaseArrow.vue';
import LocLink from '../base/LocLink.vue';
export default {
  data() {
    return {
      items: null,
      slideCnt: 0,
      slideShowActive: true,
      slideShow: null,
      slideShowDelay: 5
    };
  },
  components: {
    BaseArrow,
    LocLink
  },
  props: [
    'item',
    'productTerm',
    'imageRight',
    'isSlideShow',
    'type'
  ],
  mounted() {

    let currentProduct = document.getElementById(this.$props.type + '-image' + this.$data.slideCnt);
    let currentButton = document.getElementById(this.$props.type + '-button' + this.$data.slideCnt);
    currentButton.classList.add('discover-selected');
    gsap.set(currentProduct, {
      left: 0,
    });

    if (this.$props.isSlideShow) {
      this.$data.slideShow = gsap.delayedCall(this.$data.slideShowDelay, this.advanceSlide);
    } else {
      this.slideShowActive = false;
    }
  },
  unmounted() {

    if (this.$data.slideShow) {
      this.$data.slideShow.kill();
    }
  },
  computed: {
    prodTerm () {
      return this.$props.productTerm;
    },
    products() {
      return this.$props.item.products;
    },
    getItem() {
      return this.$props.item;
    },
    getProduct() {
      return this.products[this.$data.slideCnt];
    },
    getLayout() {
      return (this.$props.isRight) ? 'reverse' : '';
    }
  },
  methods: {

    stopSlideShow() {
      if (this.$data.slideShow) {
        this.$data.slideShow.kill();
      }
    },

    advanceSlide() {

      let next = this.$data.slideCnt + 1;
      if (next === this.products.length) {
        next = 0;
      }
      this.handleProduct(next);
      this.$data.slideShow = gsap.delayedCall(this.$data.slideShowDelay, this.advanceSlide);
    },

    handleProduct(index) {

      if (index === this.$data.slideCnt) {
        return;
      }
      this.stopSlideShow();
      let currentProduct = document.getElementById(this.$props.type + '-image' + this.$data.slideCnt);
      let currentButton = document.getElementById(this.$props.type + '-button' + this.$data.slideCnt);
      if (currentButton) {
        currentButton.classList.remove('discover-selected');
      }
      gsap.fromTo(currentProduct, {
        duration: 1,
        left: 0,
        zIndex: 0
      }, {
        left: -500
      });
      this.$data.slideCnt = index;
      let product = document.getElementById(this.$props.type + '-image' + this.$data.slideCnt);
      let button = document.getElementById(this.$props.type + '-button' + this.$data.slideCnt);
      button.classList.add('discover-selected');
      gsap.fromTo(product, {
        duration: 1,
        left: 500,
        zIndex: 1
      }, {
        left: 0
      });
      gsap.fromTo(this.$refs.linkBox, {
        alpha: 0,
        duration: .2
      }, {
        alpha: 1
      });
    },
    handleHoverArrow() {
      this.$refs.arrow.handleOver();
      this.stopSlideShow();
    },
    handleLeaveArrow() {
      this.$refs.arrow.handleOut();
    }
  },
};
</script>
<template>
  <div class="discover-product-container">

    <div :class="getLayout" class="discover-content">

      <div class="discover-image-box">
        <div class="discover-image" v-for="element, index in products" :id="type + '-image' + index">
          <img class="discover-img" :src="$rt(element.imageUri)">
        </div>
      </div>

      <div class="discover-details">

        <h3 class="product-discover-header" v-if="prodTerm">{{ $rt(prodTerm.mltText) }}</h3>
        <h2 class="product-discover-title">{{ $rt(getItem.title) }}</h2>
        <p>{{ $rt(getItem.paragraph) }}</p>

        <div class="discover-product-button-box">
          <div class="discover-product-button" v-for="element, index in products" :id="type + '-button' + index"
            @mouseover="stopSlideShow" @click="handleProduct(index)">
            {{ $rt(element.name) }}
          </div>
        </div>

        <div class="discover-arrow-link-box" ref="linkBox" @mouseover="handleHoverArrow" @mouseleave="handleLeaveArrow">
          <LocLink :to="$rt(getProduct.path)" class="discover-product-link">{{ $rt(getProduct.pathName) }}</LocLink>
          <BaseArrow ref="arrow" color="rgb(147, 147, 147)" hoverColor="#ff0000" class="arrow-pos" />
        </div>

      </div>

    </div>

  </div>
</template>

<style scoped>
.discover-content {
  max-width: 1200px;
  display: flex;
  height: 100%;
}
.reverse {
  flex-direction: row-reverse;
}
.discover-product-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin: 40px;
}
.discover-image-box {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}
.discover-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 500px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.discover-img {
  width: 100%;
  object-fit: contain;
}
.discover-details {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.discover-product-button {
  background-color: rgb(239, 236, 224);
  padding: 10px;
  margin: 0 1px 1px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
}
.discover-selected {
  background: #3B3B3B;
  color: #fff;
}
.discover-product-button:hover {
  background: #3B3B3B;
  color: #fff;
}

.discover-product-button:focus {
  background: #3B3B3B;
  color: #fff;
}

.discover-product-button-box {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 0;
  cursor: pointer;
}

.discover-product-link {
  font-size: 1.5rem;
  font-weight: 900;
  text-decoration: none;
  color: #3B3B3B;
  transition: all .125s;
  display: inline-block;
  position: relative;
}

.discover-arrow-link-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
}

.discover-chevron {
  fill: #A49F97;
}

.discover-arrow {
  fill: #F38B41;
}
.product-discover-header {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #3B3B3B;
  text-transform: uppercase;
}
.product-discover-title {
  font-size: 30px;
  max-width: 500px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.arrow-pos {
  margin-left: 10px;
  transform: translateY(7px);
}
@media only screen and (max-width: 850px) {
  .discover-details {
    margin-left: 0;
  }

  .discover-content {
    flex-direction: column;
    align-items: center;
  }

  .discover-image-box {
    max-width: 550px;
    min-height: 350px;
  }

  .discover-product-container {
    height: auto;
  }
}
</style>