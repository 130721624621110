<template>
  <div class="orb-container">
<div class="orb-wrap">
  <div class="orb-text">
    <h4 class="orb-title">{{ ($rt(items.orbTitle)).toUpperCase() }}</h4>
    <img class="orb-img" src="https://nelsonirrigation.com/img/orbitorfx-logo-white.svg">
    <div ref="orbCb" class="orb-callout" @click="this.handleOrbCb">{{ $rt(items.orbCbText) }}</div>
    <div ref="orbMist" class="orb-callout" @click="this.handleOrbMist">{{ $rt(items.orbMistText) }}</div>
    <div ref="orb3nv" class="orb-callout" @click="this.handleOrb3nv">{{ $rt(items.orb3nvText) }}</div>
    <div ref="orbDroplet" class="orb-callout" @click="this.handleOrbDroplet">{{ $rt(items.orbDropletText) }}</div>
  </div>
  <div class="orb-img-holder">

    <div class="orb-img-box">
      <div class="orb-callout-img" id="orbCb" v-if="getIsOrbCb" >
        <img :src="orbCb"/>
      </div>
      <div class="orb-callout-img" id="orbMist" v-if="getIsOrbMist">
        <img :src="orbMist"/>
      </div>
      <div class="orb-callout-img" id="orb3nv" v-if="getIsOrb3nv">
        <img :src="orb3nv"/>
      </div>
      <div class="orb-callout-img" id="orbCb" v-if="getIsDroplet">
        <img :src="orbDroplet"/>
      </div>

    </div>
    <img class="orb-svg" src="https://nelsonirrigation.com/img/orbitor.svg">
  </div>
</div>
</div>
</template>
<script>
import orbCb from '/svg/orb-cb.svg?url';
import orbMist from '/svg/orb-mist.svg?url';
import orb3nv from '/svg/orb-3nv.svg';
import orbDroplet from '/svg/orb-droplet.svg?url';
import gsap from 'gsap';
export default {
  props: [
    "items"
  ],
  computed : {
    getIsOrbCb () {
      return this.isOrbCb;
    },
    getIsOrbMist () {
      return this.isOrbMist;
    },
    getIsOrb3nv () {
      return this.isOrb3nv;
    },
    getIsDroplet () {
      return this.isOrbDroplet;
    }
  },
  data () {
    return {
      isOrbCb: false,
      isOrbMist: false,
      isOrb3nv: false,
      isOrbDroplet: false,
      isIntersecting: false,
      observer: null,
      orbCb,
      orbMist,
      orb3nv,
      orbDroplet
    };
  },
  mounted() {

    this.observer = new IntersectionObserver(this.onIntersection, {
      root: null,
      threshold: .5
    })
    this.observer.observe( document.querySelector('.orb-container') )

  },
  methods: {

    onIntersection (entries) {

      this.isIntersecting = entries[0].isIntersecting;
      if(this.isIntersecting) {
        this.handleOrbCb();
        this.observer.unobserve( document.querySelector('.orb-container') );
      }

    },
    handleOrbCb () {
      this.resetIsOrb();
      gsap.to(this.$refs.orbCb, {
        backgroundColor: 'white',
        color: 'black'
      });
      this.isOrbCb = true;
    },
    handleOrbMist () {
      this.resetIsOrb();
      gsap.to(this.$refs.orbMist, {
        backgroundColor: 'white',
        color: 'black'
      });
      this.isOrbMist = true;
    },
    handleOrb3nv () {
      this.resetIsOrb();
      gsap.to(this.$refs.orb3nv, {
        backgroundColor: 'white',
        color: 'black'
      });
      this.isOrb3nv = true;
    },
    handleOrbDroplet () {
      this.resetIsOrb();
      gsap.to(this.$refs.orbDroplet, {
        backgroundColor: 'white',
        color: 'black'
      });
      this.isOrbDroplet = true;
    },
    resetIsOrb () {
      gsap.to(this.$refs.orbCb, {
        backgroundColor: 'black',
        color: 'white'
      });
      gsap.to(this.$refs.orbMist, {
        backgroundColor: 'black',
        color: 'white'
      });
      gsap.to(this.$refs.orb3nv, {
        backgroundColor: 'black',
        color: 'white'
      });
      gsap.to(this.$refs.orbDroplet, {
        backgroundColor: 'black',
        color: 'white'
      });
      this.isOrbMist = false;
      this.isOrbCb = false;
      this.isOrb3nv = false;
      this.isOrbDroplet = false;
    }
  }
};
</script>
<style scoped>

.orb-container {
  background-color: black;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.orb-wrap {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orb-svg {
  width: 100%;
  position: relative;
  z-index: 2;
}

.orb-callout-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.orb-callout {
  cursor: pointer;
  border: 1px solid #666;
  padding: 40px;
  background-image: linear-gradient(#F48B41,#F48B41);
  background-size: 2px 80%;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.2em;
}

.orb-callout:hover {
  border: 1px solid white;
}
.orb-img-box {
  top: 20px;
  position: relative;

}
.orb-text {
  width: 100%;
  max-width: 500px;
  flex: 1;
}
.orb-title {
  color: #fff;
  border-bottom: 1px solid #FFF;
  padding-bottom:15px;
  font-size: 16px;
}
.orb-img {
  padding-top: 15px;
  padding-bottom: 25px;

}
.orb-svg {

  position: relative;
  z-index: 2;
}

.orb-img-holder {
  flex: 1;
  max-width: 375px;
}

@media only screen and (max-width: 950px) {
  .orb-text {
    margin-right: 20px;
  }
  .orb-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .orb-img-box {
    top: 17px !important;
  }

}

@media only screen and (max-width: 700px) {


  .orb-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .orb-callout {
    font-size: 15px;
    padding: 20px;
  }
  .orb-callout {
    margin-bottom: 20px;
  }

  .orb-img-box {
    top: 15px !important;
  }

}

@media only screen and (max-width: 550px) {

  .orb-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .orb-callout {
    margin-bottom: 10px;
  }

  .orb-img-box {
    top: 10px !important;
  }

  .orb-img {
    width: 100%;
    max-width: 150px;
  }
}


</style>