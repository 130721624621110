<template>
  <div class="ann-home-container" v-if="this.announcementsData">
    <div class="ann-home-section-title">{{getHomeTitle}}</div>
    <div class="ann-home-section-subtitle">{{getSubTitle}}</div>
    <div class="ann-home-header">
      <div class="ann-home-header-title">{{getSectionTitle}}</div>
      <LocLink to="/announcements" >
        <div class="ann-home-view-all" >{{getViewAll}}</div>
      </LocLink>
    </div>
    <hr class="ann-home-hr">
    <div class="ann-home-holder" >
      <AnnouncementHomeItem v-for="(item,index) in getLatestAnnouncements" :announcement="item"></AnnouncementHomeItem>
    </div>
  </div>
</template>
<script>

import BaseArrow from "~/components/base/BaseArrow.vue";
import LocLink from "~/components/base/LocLink.vue";
import {useAnnouncementsStore} from "~/store/AnnouncementsStore";
import AnnouncementHomeItem from "~/components/announcements/home/AnnouncementHomeItem.vue";

export default {

  data () {
    return {
      announcementsData: null,
      announcements: null
    }
  },
  setup() {
    useAnnouncementsStore().getAllAnnouncements();
  },
  components: {
    LocLink,
    BaseArrow,
    AnnouncementHomeItem
  },

  mounted () {
    this.announcementsData = this.$tm("announcements");

  },
  computed: {
    getSectionTitle () {
      return this.$rt(this.announcementsData.title);
    },
    getHomeTitle () {
      return this.$rt(this.announcementsData.homeTitle);
    },
    getSubTitle () {
      return this.$rt(this.announcementsData.subTitle);
    },
    getViewAll () {
      return this.$rt(this.announcementsData.viewAll);
    },
    getLatestAnnouncements () {
      return this.announcements = useAnnouncementsStore().getAnnouncements.slice(0,3);
    }
  },
  methods: {
    handleViewAll () {

    }
  }
};
</script>
<style scoped>

.ann-home-header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

}

.ann-home-holder {
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 20px;
}

.ann-home-section-subtitle {
  text-align: center;
  margin-bottom: 20px;
}

.ann-home-section-title {
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

.ann-home-header-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 15px;
}

.ann-home-view-all {
  text-decoration: underline;
  font-weight: bold;
  font-size: 15px;
  color: rgb(59,59,59);
}

.ann-home-container {
  margin: 0 50px;
}

.ann-home-hr {
  margin-bottom: 15px;
}
</style>
