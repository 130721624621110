<template>
  <div class="main-slide">

    <div class="slideshow-container">
            <div v-for="i in [currentIndex]"
            class="slideshow-image"
              :style="
                'background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgb(100, 106, 122) 0%, rgba(100, 106, 122, 0) 40%), url(' +
                currentImage +
                ');'
              "
            ></div>
    </div>

    <div class="slideshow-header" v-if="titles">
      <h1 class="slideshow-title">{{ $rt(titles.slideTitle) }}</h1>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      images: [
        '/images/general/home-hero4.webp',
        '/images/general/home-hero6.webp',
        '/images/general/home-hero1.webp',
        '/images/general/home-hero2.webp',
        '/images/general/home-hero3.webp',
        '/images/general/home-hero5.webp',
      ],
      timer: null,
      currentIndex: 0,
    };
  },
  props: [
    'items'
  ],
  mounted () {
    this.startSlides();
  },
  methods: {
    startSlides () {
      this.timer = setInterval(this.next, 10000);
    },
    next () {
      this.currentIndex += 1;
    },
    prev () {
      this.currentIndex -= 1;
    },
  },
  computed: {
    titles () {
      return this.$props.items;
    },
    currentImage () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>
<style scoped>

.main-slide {
  position: relative;
  pointer-events: none;
}

.slideshow-header {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 30px;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
  text-shadow: rgb(100, 106, 122) 0px 0px 2px;
}
.slideshow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 640px;
}
.slideshow-title {
  padding: 0;
  margin: 0;
  width: 100%;
    max-width: 700px;
  font-size: 50px;
  font-weight: 900;
}
.slideshow-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 640px;
  position: relative;
  left: 0px;
  top: 0px;

}

@media only screen and (max-width: 700px) {
  .slideshow-container {
    max-height: 250px;
  }
  .slideshow-title {
    font-size: 35px;
  }
}

</style>
