<template>
<div class="ann-home-item">
    <div class="ann-home-img">
      <img class="ann-home-img-src" :src="getImageFromCopy"/></div>
    <div class="ann-home-info">
      <div class="ann-home-date">{{getDate}}</div>
      <div class="ann-home-title">{{announcement.title}}</div>
      <div class="ann-home-blurb" v-html="announcement.summary"></div>
    </div>
  <div class="ann-home-bottom">
    <hr class="ann-home-hr">
    <LocLink v-if="announcement.url !== null" class="ann-home-read-more-link" :to="/announcements/ + announcement.url" >
      <div class="ann-home-read-more" @mouseover="handleHoverArrow" @mouseleave="handleLeaveArrow">Read More
        <BaseArrow ref="arrow" color="rgb(147, 147, 147)" hoverColor="#ff0000" class="ann-arrow-pos"/>
      </div>
    </LocLink>
  </div>
</div>
</template>
<script>

import BaseArrow from "~/components/base/BaseArrow.vue";
import LocLink from "~/components/base/LocLink.vue";

export default {

  data () {
    return {

    }
  },
  components: {
    LocLink,
    BaseArrow
  },
  props: [
    "announcement"
  ],
  mounted () {

  },
  computed: {
    getImageFromCopy () {
      return '/images/announcementsimg/ann-image_' + this.announcement.announcementID + '-0.jpg';
    },
    getDate () {
      let dateIn = Date.parse(this.announcement.publishDate);
      let dateOut = new Date(dateIn);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return dateOut.toLocaleDateString(undefined, options);
    }
  },
  methods: {
    handleHoverArrow() {
      this.$refs.arrow.handleOver();
      //this.stopSlideShow();
    },
    handleLeaveArrow() {
      this.$refs.arrow.handleOut();
    }
  }
};
</script>
<style scoped>


.ann-home-img {
  padding: 5px;
  max-height: 250px;
}

.ann-home-info {
  margin: 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ann-home-date {
  font-size: 13px;
  margin-top:20px;
  margin-bottom:10px;
}

.ann-home-title {
  font-size: 20px;
  font-weight: bold;
}

.ann-home-blurb {
  font-size: 14px;
  margin-top: 15px;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.ann-home-read-more-link {
  text-decoration: none !important;
}

.ann-home-read-more {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 20px 0 !important;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
  color: #222222;
}

.ann-arrow-pos {
  transform: translateY(2px);
}

.ann-home-img-src {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ann-home-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #e7e7e7;
  padding-bottom: 0px;
}

.ann-home-hr {
  border: 1px solid #e7e7e7;
  width: 100%;
}


.ann-home-bottom {
  margin: 0 15px;
}

@media only screen and (max-width: 700px) {

  .ann-img {
    width: 150px;
    height: 120px;
    background-color: blue;
  }

  .ann-info {
    gap: 0;
    margin: 20px 0 0;
  }

  .ann-date {
    font-size: 13px;
  }

  .ann-title {
    font-size: 20px;
  }

  .ann-blurb {
    line-height: 1.5em;
  }

  .ann-read-more {
    margin-top: 15px;
  }

}


</style>
