<script>
import ProductDiscover from '~/components/main/ProductDiscover.vue';
import ContentBanner from '~/components/main/ContentBanner.vue';
import SlideShow from '~/components/main/SlideShow.vue';
import Solutions from '~/components/main/Solutions.vue';
import ProductSpotlight from '~/components/main/ProductSpotlight.vue';
import LocLink from '~/components/base/LocLink.vue';
import AnnouncementsHome from "~/components/announcements/AnnouncementsHome.vue";
export default {
  setup() {
  },
  data() {
    return {
      mainData: null,
      extraTerms: null
    };
  },
  mounted() {
    this.mainData = this.$tm('home');
    this.extraTerms = this.$tm('extraTerms');

    this.mainData[1].categories.forEach((item) => {
      item.isArrow = false;
    });
  },
  computed: {
    MainData() {
      if (this.mainData)
        return this.mainData;
      else return [];
    },
    ourProducts() {
      let prodTerm = null;
      if (this.extraTerms.length) {
        prodTerm = this.extraTerms.find((element) => {
          if (element.mlVar != null) {
             return this.$rt(element.mlVar) === 'ourProducts'
          }
      });
      }
      return prodTerm;
    },
    ourSolutions() {
      let solTerm = null;
      if (this.extraTerms.length) {
        solTerm = this.extraTerms.find((element) => {
          if (element.mlVar != null) {
            return this.$rt(element.mlVar) == 'ourSolutions'
          } 
        });
      }
      return solTerm;
    }
  },
  methods: {
    handleHover(item) {
      item.isArrow = true;
    },
    handleLeave(item) {
      item.isArrow = false;
    }
  },
  components: {
    ProductDiscover,
    SlideShow,
    ContentBanner,
    ProductSpotlight,
    Solutions,
    LocLink,
    AnnouncementsHome
  }
};
</script>
<template>
  <div >
    <Meta v-if="MainData[0]" name="description" :content="$rt(MainData[0].slideDescript)" />
  </div>
  <SlideShow v-if="MainData[0]" :items="MainData[0]" />
  <div class="sub-holder" v-if="MainData[0]">
    <p class="fh-center">{{ $rt(MainData[0].slideDescript) }}</p>
  </div>
  <Solutions v-if="MainData.length > 0" :solutionTerm="ourSolutions" :items="MainData[1]" />
  <ContentBanner v-if="MainData.length > 0" :items="MainData[2]" />
  <ProductDiscover v-if="MainData.length > 0" :productTerm="ourProducts" :item="MainData[3]" type="sprinklers"
    is-slide-show="true" />
  <ProductSpotlight v-if="MainData.length > 0" :items="MainData[4]" />
  <ProductDiscover v-if="MainData.length > 0" :productTerm="ourProducts" :item="MainData[5]" type="control"
    image-right="true" is-slide-show="true" />
  <ContentBanner v-if="MainData.length > 0" :items="MainData[6]" />
  <div class="home-dealer-container" v-if="MainData.length > 0">
    <AnnouncementsHome/>
    <div class="home-dealer-box">
      <h2 class="find-a-dealer-title">{{ $rt(MainData[7].title) }}</h2>
      <p class="find-a-dealer-text">{{ $rt(MainData[7].paragraph) }}</p>
      <div class="home-dealer-link-box">
        <LocLink class="home-dealer-link" to="/dealers">{{ $rt(MainData[7].buttonText) }}</LocLink>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fh-center {
  text-align: center;
  max-width: 700px;
  font-size: 30px;
  line-height: 1.3;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}


.home-dealer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-dealer-box {
  text-align: center;
  padding: 1% 20px;
  max-width: 900px;
  font-size: 1.45rem;
  margin: 0 auto;
}

.home-dealer-link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 20px;
}

.home-dealer-link {
  cursor: pointer;
  border: 2px solid #F48B41;
  background: #fff;
  width: 260px;
  text-decoration: none;
  text-align: center;
  padding: 0.85em 0;
  margin-bottom: 1em;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  transition: all .125s;
  border-radius: 1px;
  color: #3B3B3B;
  ;
}

.home-dealer-link:hover {
  background-color: #F48B41;
}

@media only screen and (max-width: 950px) {
  .central-box {
    grid-template-columns: 1fr 1fr;
  }

}

@media only screen and (max-width: 700px) {
  .fh-center {
    font-size: 25px !important;
  }

  .find-a-dealer-title {
    font-size: 20px !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .find-a-dealer-text {
    font-size: 16px !important;
    max-width: 600px;
    margin-bottom: 20px;
  }

}


.sub-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.find-a-dealer-title {
  font-size: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.find-a-dealer-text {
  font-size: 23px;
  max-width: 600px;
  margin-bottom: 20px;
}
</style>
